/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { Layout, PageHeading, PublicationsList } from "components";

function ExhibitionsPage({
  data: {
    allSanityPublication: { publications },
  },
}) {
  return (
    <Layout pageTitle={"Publications"}>
      <PageHeading heading="Publications" />

      <PublicationsList
        publications={publications.map(({ node: publication }) => publication)}
      />
    </Layout>
  );
}

export const query = graphql`
  {
    allSanityPublication(sort: { fields: date, order: DESC }) {
      publications: edges {
        node {
          _id
          category {
            category
          }
          title
          publication_date_site
          image {
            asset {
              gatsbyImageData(height: 220, width: 290)
            }
          }
        }
      }
    }
  }
`;

export default ExhibitionsPage;
